import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import styles from '../styles/Navigation.module.css';

const Navigation = () => {
  const { user, logout } = useAuth();

  const agencyMenuItems = [
    { to: '/agency-dashboard', label: 'Dashboard' },
    { to: '/managed-influencers', label: 'Portfolio' },
    { to: '/campaigns', label: 'Campaigns' },
    { to: '/analytics', label: 'Analytics' },
    { to: '/settings', label: 'Settings' },
    { to: '/connections-manager', label: 'Connections Manager' }
  ];

  const influencerMenuItems = [
    { to: '/influencer-dashboard', label: 'Dashboard' },
    { to: '/campaigns', label: 'Campaigns' },
    { to: '/analytics', label: 'Analytics' },
    { to: '/settings', label: 'Settings' },
    { to: '/social-media-connections', label: 'Connect Socials' },
    { to: '/connections-manager', label: 'Connections Manager' }
  ];

  const menuItems = user && user.role === 'agency' ? agencyMenuItems : influencerMenuItems;

  return (
    <nav className={styles.navigation}>
      <ul className={styles.navList}>
        {menuItems.map((item) => (
          <li key={item.to} className={styles.navItem}>
            <Link to={item.to} className={styles.navLink}>
              {item.label}
            </Link>
          </li>
        ))}
        <li className={styles.navItem}>
          <button onClick={logout} className={styles.logoutButton}>
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
