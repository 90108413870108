import React from 'react';

const Campaigns = () => {
  return (
    <div>
      <h1>Campaigns</h1>
      {/* Add campaign-related content here */}
    </div>
  );
};

export default Campaigns;
