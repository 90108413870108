import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Layout from './components/Layout/Layout';
import Home from './components/Home';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import SocialMediaConnectionsPage from './components/SocialMediaConnectionsPage';
import InfluencerDashboard from './components/Dashboard/InfluencerDashboard';
import AgencyDashboard from './components/Dashboard/AgencyDashboard';
import ManagedInfluencers from './components/Agency/ManagedInfluencers';
import Campaigns from './components/Campaigns/Campaigns';
import Analytics from './components/Analytics/Analytics';
import Settings from './components/Settings/Settings';
import PrivateRoute from './components/Common/PrivateRoute';
import Impressum from './components/Impressum';
import Privacy from './components/PrivacyPolicy';
import ConnectionsManager from './components/ConnectionsManager';
import InstagramCallback from './components/Auth/InstagramCallback';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ErrorBoundary>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/social-media-connections" element={<PrivateRoute component={SocialMediaConnectionsPage} />} />
              <Route path="/influencer-dashboard" element={<PrivateRoute component={InfluencerDashboard} />} />
              <Route path="/agency-dashboard" element={<PrivateRoute component={AgencyDashboard} />} />
              <Route path="/managed-influencers" element={<PrivateRoute component={ManagedInfluencers} />} />
              <Route path="/campaigns" element={<PrivateRoute component={Campaigns} />} />
              <Route path="/analytics" element={<PrivateRoute component={Analytics} />} />
              <Route path="/settings" element={<PrivateRoute component={Settings} />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/connections-manager" element={<PrivateRoute component={ConnectionsManager} />} />
              <Route path="/auth/instagram/callback" element={<InstagramCallback />} />
            </Routes>
          </Layout>
        </ErrorBoundary>
      </Router>
    </AuthProvider>
  );
};

export default App;