import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getConnectedAccounts, disconnectSocialMedia } from '../utils/api';
import YouTubeConnect from './SocialMediaConnect/YouTubeConnect';
import InstagramConnect from './SocialMediaConnect/InstagramConnect';
import TwitchConnect from './SocialMediaConnect/TwitchConnect';
import TikTokConnect from './SocialMediaConnect/TikTokConnect';

const SocialMediaConnectionsPage = () => {
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, refreshToken } = useAuth();
  const navigate = useNavigate();

  const fetchConnectedAccounts = useCallback(async () => {
    try {
      console.log('Attempting to fetch connected accounts');
      const accounts = await getConnectedAccounts(user.token);
      console.log('Received response:', accounts);
      setConnectedAccounts(Array.isArray(accounts) ? accounts : []);
    } catch (error) {
      console.error('Error fetching connected accounts:', error);
      if (error.response && error.response.status === 401) {
        try {
          const newToken = await refreshToken();
          if (newToken) {
            const accounts = await getConnectedAccounts(newToken);
            setConnectedAccounts(Array.isArray(accounts) ? accounts : []);
          } else {
            navigate('/login');
          }
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          navigate('/login');
        }
      } else {
        setError('Failed to fetch connected accounts. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  }, [user, refreshToken, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const connectionStatus = urlParams.get('connection');
    
    if (connectionStatus === 'success' || !connectedAccounts.length) {
      fetchConnectedAccounts();
    }
  }, [fetchConnectedAccounts, connectedAccounts.length]);

  const handleDisconnect = async (platform) => {
    try {
      await disconnectSocialMedia(platform);
      setConnectedAccounts(prevAccounts => prevAccounts.filter(acc => acc.platform !== platform));
    } catch (error) {
      console.error(`Error disconnecting ${platform}:`, error);
    }
  };

  if (!user || !user.token) {
    return <div>Please log in to view your connected accounts.</div>;
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Connected Social Media Accounts</h2>
      {connectedAccounts.length > 0 ? (
        connectedAccounts.map((account) => (
          <div key={account.platform}>
            <p>{account.platform}</p>
            <button onClick={() => handleDisconnect(account.platform)}>Disconnect</button>
          </div>
        ))
      ) : (
        <p>No connected accounts found.</p>
      )}
      <h3>Connect New Accounts</h3>
      <YouTubeConnect />
      <InstagramConnect />
      <TwitchConnect />
      <TikTokConnect />
    </div>
  );
};

export default SocialMediaConnectionsPage;