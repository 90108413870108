
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { sendInvitation, getPendingInvitations, acceptInvitation, getConnections, removeConnection } from '../utils/api';

const ConnectionsManager = () => {
  const [email, setEmail] = useState('');
  const [invitations, setInvitations] = useState([]);
  const [connections, setConnections] = useState([]);
  const [message, setMessage] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    fetchInvitations();
    fetchConnections();
  }, []);

  const fetchInvitations = async () => {
    try {
      const data = await getPendingInvitations(user.token);
      setInvitations(data);
    } catch (error) {
      console.error('Error fetching invitations:', error);
    }
  };

  const fetchConnections = async () => {
    try {
      const data = await getConnections(user.token);
      setConnections(data);
    } catch (error) {
      console.error('Error fetching connections:', error);
    }
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    try {
      await sendInvitation(user.token, email);
      setMessage('Invitation sent successfully');
      setEmail('');
    } catch (error) {
      setMessage('Failed to send invitation');
    }
  };

  const handleAccept = async (invitationId) => {
    try {
      await acceptInvitation(user.token, invitationId);
      fetchInvitations();
      fetchConnections();
    } catch (error) {
      console.error('Error accepting invitation:', error);
    }
  };

  const handleRemove = async (connectionId) => {
    try {
      await removeConnection(user.token, connectionId);
      fetchConnections();
    } catch (error) {
      console.error('Error removing connection:', error);
    }
  };

  return (
    <div>
      <h1>Connections Manager</h1>
      
      <section>
        <h2>Invite {user.role === 'influencer' ? 'Agency' : 'Influencer'}</h2>
        <form onSubmit={handleInvite}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={`${user.role === 'influencer' ? 'Agency' : 'Influencer'} Email`}
            required
          />
          <button type="submit">Send Invitation</button>
        </form>
        {message && <p>{message}</p>}
      </section>

      <section>
        <h2>Pending Invitations</h2>
        {invitations.map(invitation => (
          <div key={invitation._id}>
            <p>{invitation.sender.username} wants to connect</p>
            <button onClick={() => handleAccept(invitation._id)}>Accept</button>
          </div>
        ))}
      </section>

      <section>
        <h2>My Connections</h2>
        {connections.map(connection => (
          <div key={connection._id}>
            <p>{connection.username}</p>
            <button onClick={() => handleRemove(connection._id)}>Remove</button>
          </div>
        ))}
      </section>
    </div>
  );
};

export default ConnectionsManager;
