import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../../utils/api';
import '../styles/Register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: 'influencer',
    agreeToTerms: false
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const checkPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/[0-9]+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;
    return strength;
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.username.trim()) {
      formErrors.username = 'Username is required';
    } else if (formData.username.length < 3) {
      formErrors.username = 'Username must be at least 3 characters';
    }

    if (!formData.email.trim()) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email is invalid';
    }

    if (!formData.password) {
      formErrors.password = 'Password is required';
    } else {
      const passwordStrength = checkPasswordStrength(formData.password);
      if (passwordStrength < 4) {
        formErrors.password = 'Password is not strong enough. It should contain at least 8 characters, including uppercase, lowercase, numbers, and special characters.';
      }
    }

    if (formData.password !== formData.confirmPassword) {
      formErrors.confirmPassword = 'Passwords do not match';
    }

    if (!formData.agreeToTerms) {
      formErrors.agreeToTerms = 'You must agree to the terms of service';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const { username, email, password, role } = formData;
        await register({ username, email, password, role });
        navigate('/login');
      } catch (err) {
        console.error('Registration error:', err.response?.data || err.message);
        setErrors({ form: 'Registration failed. Please try again.' });
      }
    }
  };

  return (
    <div className="register-container">
      <h2>Create an Account</h2>
      {errors.submit && <p className="error">{errors.submit}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
          {errors.username && <p className="error">{errors.username}</p>}
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          {errors.password && <p className="error">{errors.password}</p>}
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
          {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
        </div>
        <div>
          <label htmlFor="role">Role</label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <option value="influencer">Influencer</option>
            <option value="agency">Agency</option>
          </select>
        </div>
        <div>
          <input
            type="checkbox"
            id="agreeToTerms"
            name="agreeToTerms"
            checked={formData.agreeToTerms}
            onChange={handleChange}
            required
          />
          <label htmlFor="agreeToTerms">
            I agree to the <Link to="/terms">Terms of Service</Link>
          </label>
          {errors.agreeToTerms && <p className="error">{errors.agreeToTerms}</p>}
        </div>
        <input type="hidden" name="_csrf" value={document.cookie.split('=')[1]} />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;
