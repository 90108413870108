  import React from 'react';
  import Header from '../Common/Header';
  import Footer from '../Common/Footer';
  import styles from './Layout.module.css';

  const Layout = ({ children }) => {
    return (
      <div className={styles.appContainer}>
        <Header />
        <main className={styles.mainContent}>
          {children}
        </main>
        <Footer />
      </div>
    );
  };

  export default Layout;
