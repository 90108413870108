import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getConnections, getInfluencerInsights } from '../../utils/api';
import styles from '../styles/Dashboard.module.css';
import chartStyles from '../styles/ChartStyles.module.css';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AgencyDashboard = () => {
  const [connectedInfluencers, setConnectedInfluencers] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState('youtube');
  const [influencerInsights, setInfluencerInsights] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const { user } = useAuth();

  const refreshData = async () => {
    setRefreshing(true);
    try {
      const data = await getConnections(user.token);
      setConnectedInfluencers(data);
      if (selectedInfluencer) {
        const insights = await getInfluencerInsights(user.token, selectedInfluencer, selectedPlatform);
        setInfluencerInsights(insights);
      }
      setLastUpdated(new Date());
    } catch (error) {
      console.error('Error refreshing data:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    refreshData();
  }, [selectedInfluencer, selectedPlatform]);

  const handleInfluencerSelect = async (influencerId) => {
    setSelectedInfluencer(influencerId);
    if (influencerId) {
      fetchInfluencerInsights(influencerId, selectedPlatform);
    }
  };

  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    if (selectedInfluencer) {
      fetchInfluencerInsights(selectedInfluencer, platform);
    }
  };

  const fetchInfluencerInsights = async (influencerId, platform) => {
    try {
      const insights = await getInfluencerInsights(user.token, influencerId, platform);
      console.log('Fetched insights:', insights);
      setInfluencerInsights(insights);
    } catch (error) {
      console.error('Error fetching influencer insights:', error);
      setInfluencerInsights(null);
      // Handle error (e.g., show error message to user)
    }
  };

  const renderViewsOverTimeChart = () => {
    if (!influencerInsights?.viewsOverTime || influencerInsights.viewsOverTime.length === 0) {
      return <p>No data available for chart</p>;
    }

    const data = {
      labels: influencerInsights.viewsOverTime.map(row => row[0]),
      datasets: [{
        label: 'Views',
        data: influencerInsights.viewsOverTime.map(row => parseInt(row[1])),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: '#e0e0e0'
          }
        },
        title: {
          display: true,
          text: 'Views Over Time',
          color: '#e0e0e0'
        },
        tooltip: {
          backgroundColor: '#333',
          titleColor: '#e0e0e0',
          bodyColor: '#e0e0e0'
        }
      },
      scales: {
        x: {
          ticks: { color: '#e0e0e0' },
          grid: { color: '#444' }
        },
        y: {
          ticks: { color: '#e0e0e0' },
          grid: { color: '#444' }
        }
      }
    };

    return <Line data={data} options={options} className={chartStyles.chart} />;
  };

  const renderOverviewStats = () => {
    if (!influencerInsights?.overviewStats || influencerInsights.overviewStats.length === 0) {
      return <p>No overview statistics available</p>;
    }
    
    const latestStats = influencerInsights.overviewStats[0];
    
    return (
      <div className={styles.overviewStats}>
        <h3>Overview Statistics (Last 28 Days)</h3>
        <p>Views: {latestStats[1].toLocaleString()}</p>
        <p>Watch Time: {Math.round(latestStats[2] / 60)} hours</p>
        <p>Avg View Duration: {Math.round(latestStats[3])} seconds</p>
        <p>Avg View Percentage: {latestStats[4].toFixed(2)}%</p>
        <p>Subscribers Gained: {latestStats[5].toLocaleString()}</p>
      </div>
    );
  };

  const renderRealTimeData = () => {
    if (!influencerInsights?.realTimeData) return <p>No real-time data available</p>;
    return (
      <div className={styles.realTimeData}>
        <h3>Real-Time Data</h3>
        <p>Current Views: {influencerInsights.realTimeData.viewCount.toLocaleString()}</p>
        <p>Current Subscribers: {influencerInsights.realTimeData.subscriberCount.toLocaleString()}</p>
        <p>Current Videos: {influencerInsights.realTimeData.videoCount.toLocaleString()}</p>
      </div>
    );
  };

  const renderTopVideos = () => {
    if (!influencerInsights?.topVideos || influencerInsights.topVideos.length === 0) return <p>No top video data available</p>;
    return (
      <div className={styles.topVideos}>
        <h3>Top Performing Videos</h3>
        <div className={styles.videoGrid}>
          {influencerInsights.topVideos.map((video, index) => (
            <div key={index} className={styles.videoCard}>
              <a href={`https://www.youtube.com/watch?v=${video[0]}`} target="_blank" rel="noopener noreferrer">
                <img src={`https://img.youtube.com/vi/${video[0]}/mqdefault.jpg`} alt={`Video thumbnail`} className={styles.videoThumbnail} />
              </a>
              <div className={styles.videoInfo}>
                <h4>{video.title || 'Untitled'}</h4>
                <p>Views: {parseInt(video[1]).toLocaleString()}</p>
                <p>Watch Time: {Math.round(parseFloat(video[2]) / 60)} mins</p>
                <p>Avg View Duration: {Math.round(parseFloat(video[3]))} secs</p>
                <p>Avg View Percentage: {parseFloat(video[4]).toFixed(2)}%</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  const renderDemographics = () => {
    if (!influencerInsights?.demographics) return <p>No demographic data available</p>;
    
    return (
      <div className={styles.demographics}>
        <h3>Audience Demographics</h3>
        {Object.entries(influencerInsights.demographics).map(([category, data]) => (
          <div key={category}>
            <h4>{category}</h4>
            <ul className={styles.dashboardList}>
              {data.map((item, index) => (
                <li key={index} className={styles.dashboardListItem}>
                  {item.join(': ')}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.dashboard}>
      <h1>Agency Dashboard</h1>
      <div className={styles.refreshSection}>
        <button onClick={refreshData} disabled={refreshing} className={styles.refreshButton}>
          {refreshing ? 'Refreshing...' : 'Refresh Data'}
        </button>
        {lastUpdated && (
          <p className={styles.lastUpdated}>Last updated: {lastUpdated.toLocaleString()}</p>
        )}
      </div>
      <div className={styles.selectors}>
        <select onChange={(e) => handleInfluencerSelect(e.target.value)} value={selectedInfluencer || ''}>
          <option value="">Select an Influencer</option>
          {connectedInfluencers.map(influencer => (
            <option key={influencer._id} value={influencer._id}>{influencer.username}</option>
          ))}
        </select>
        <select onChange={(e) => handlePlatformChange(e.target.value)} value={selectedPlatform}>
          <option value="youtube">YouTube</option>
          <option value="instagram">Instagram</option>
          <option value="twitch">Twitch</option>
          <option value="tiktok">TikTok</option>
        </select>
      </div>
      {influencerInsights && (
        <div className={styles.influencerInsights}>
          {renderOverviewStats()}
          {renderRealTimeData()}
          {renderTopVideos()}
          {renderDemographics()}
          <div className={styles.viewsOverTime}>
            <h3>Views Over Time</h3>
            {renderViewsOverTimeChart()}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgencyDashboard;
