import React, { createContext, useState, useContext, useEffect } from 'react';
import { refreshToken as apiRefreshToken } from '../utils/api';
import jwt_decode from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const storedUser = localStorage.getItem('user');
      const storedRefreshToken = localStorage.getItem('refreshToken');
      console.log('Stored user:', storedUser);
      console.log('Stored refresh token:', storedRefreshToken); 
      if (storedUser && storedRefreshToken) {
        const parsedUser = JSON.parse(storedUser);
        const tokenExp = jwt_decode(parsedUser.token).exp;
        const currentTime = Math.floor(Date.now() / 1000);
        if (tokenExp - currentTime <= 300) {
          try {
            const response = await apiRefreshToken(storedRefreshToken);
            const { accessToken } = response.data;
            const updatedUser = { ...parsedUser, token: accessToken };
            setUser(updatedUser);
            localStorage.setItem('user', JSON.stringify(updatedUser));
          } catch (error) {
            console.error('Failed to refresh token:', error);
            logout();
          }
        } else {
          setUser(parsedUser);
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const login = (userData) => {
    const { userId, username, role, accessToken, refreshToken } = userData;
    const userInfo = { userId, username, role, token: accessToken, connections: [] };
    setUser(userInfo);
    localStorage.setItem('user', JSON.stringify(userInfo));
    localStorage.setItem('refreshToken', refreshToken);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('refreshToken');
  };

  const refreshToken = async () => {
    try {
      const storedRefreshToken = localStorage.getItem('refreshToken');
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !storedRefreshToken) {
        throw new Error('No user or refresh token found');
      }
      
      const tokenExp = jwt_decode(user.token).exp;
      const currentTime = Math.floor(Date.now() / 1000);
      if (tokenExp - currentTime > 300) {
        return user.token;
      }
  
      const response = await apiRefreshToken(storedRefreshToken);
      const { accessToken } = response.data;
      const updatedUser = { ...user, token: accessToken };
      setUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
      return accessToken;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      logout();
      throw new Error('Session expired: Please log in again');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);