import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <p className={styles.footerText}>© 2023 Influencer Management Platform. All rights reserved.</p>
        <nav>
          <ul className={styles.footerLinks}>
            <li><Link to="/about" className={styles.footerLink}>About Us</Link></li>
            <li><Link to="/contact" className={styles.footerLink}>Contact</Link></li>
            <li><Link to="/PrivacyPolicy" className={styles.footerLink}>Privacy Policy</Link></li>
            <li><Link to="/terms" className={styles.footerLink}>Terms of Service</Link></li>
            <li><Link to="/impressum" className={styles.footerLink}>Impressum</Link></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;