import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { connectSocialMedia } from '../../utils/api';

const InstagramConnect = () => {
  const { user, refreshToken } = useAuth();
  const handleConnect = async () => {
    try {
      await refreshToken();
      const response = await connectSocialMedia('facebook', user.token);
      if (response.authUrl) {
        window.location.href = response.authUrl;
      } else {
        console.error('No auth URL received. Full response:', response);
      }
    } catch (error) {
      console.error('Failed to initiate Facebook connection:', error);
    }
  };

  return <button onClick={handleConnect}>Connect Instagram via Facebook</button>;
};

export default InstagramConnect;