import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { connectSocialMedia } from '../../utils/api';

const TwitchConnect = () => {
  const { user, refreshToken } = useAuth();

  const handleConnect = async () => {
    try {
      await refreshToken();
      const authUrl = await connectSocialMedia('twitch', user.token);
      if (authUrl) {
        window.location.href = authUrl;
      } else {
        console.error('No auth URL received');
      }
    } catch (error) {
      console.error('Failed to initiate Twitch connection:', error);
    }
  };

  return <button onClick={handleConnect}>Connect Twitch</button>;
};

export default TwitchConnect;