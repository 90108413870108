import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getManagedInfluencers } from '../../utils/api';
import styles from '../styles/ManagedInfluencers.module.css';

const ManagedInfluencers = () => {
  const [influencers, setInfluencers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchInfluencers = async () => {
      try {
        const data = await getManagedInfluencers(user.token);
        setInfluencers(data);
      } catch (error) {
        console.error('Error fetching managed influencers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInfluencers();
  }, [user.token]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className={styles.managedInfluencers}>
      <h1>Managed Influencers</h1>
      <ul className={styles.influencerList}>
        {influencers.map(influencer => (
          <li key={influencer._id} className={styles.influencerItem}>
            <span className={styles.influencerName}>{influencer.username}</span>
            <div className={styles.influencerDetails}>
              <p>Email: {influencer.email}</p>
              <p>Connected Accounts: {influencer.socialMediaAccounts.length}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManagedInfluencers;
