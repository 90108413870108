import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import styles from '../styles/Header.module.css';
import Navigation from './Navigation';

const Header = () => {
  const { user } = useAuth();

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <Link to="/" className={styles.logo}>LUENI</Link>
        {user ? (
          <Navigation />
        ) : (
          <nav className={styles.navigation}>
            <ul className={styles.navList}>
              <li className={styles.navItem}><Link to="/login" className={styles.navLink}>Login</Link></li>
              <li className={styles.navItem}><Link to="/register" className={styles.navLink}>Register</Link></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;