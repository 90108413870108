import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const Home = () => {
  const { user } = useAuth();
  return (
    <div>
      <h2>Welcome to Influencer Management</h2>
      {user && user.role === 'agency' && <p>Agency Dashboard</p>}
      {user && user.role === 'influencer' && <p>Influencer Dashboard</p>}
    </div>
  );
};

export default Home;