  import React, { useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  import axios from 'axios';

  const InstagramCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const fetchTokens = async () => {
        const code = new URLSearchParams(window.location.search).get('code');
        if (code) {
          try {
            const response = await axios.get(`/api/ig/auth/instagram/callback?code=${code}`);
            if (response.status === 200) {
              navigate('/social-media-connections');
            } else {
              console.error('Error during Instagram callback:', response);
            }
          } catch (error) {
            console.error('Error during Instagram callback:', error);
          }
        }
      };

      fetchTokens();
    }, [navigate]);

    return <div>Connecting to Instagram...</div>;
  };

  export default InstagramCallback;
