import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  console.log('PrivateRoute - user:', user);

  if (user) {
    console.log('User is authenticated, rendering the component');
    return <Component {...rest} />;
  } else {
    console.log('User is not authenticated, redirecting to login');
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;