import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext.js';
import styles from '../styles/Dashboard.module.css';
import chartStyles from '../styles/ChartStyles.module.css';
import { getConnectedAccounts, getYouTubeAnalytics, getInstagramInsights } from '../../utils/api';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const InfluencerDashboard = () => {
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [youtubeInsights, setYoutubeInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { user, refreshToken } = useAuth();
  const [selectedPlatform, setSelectedPlatform] = useState('youtube');
  const [platformInsights, setPlatformInsights] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const refreshData = async () => {
    setRefreshing(true);
    try {
      const accountsResponse = await getConnectedAccounts(user.token);
      setConnectedAccounts(accountsResponse);
      
      const selectedAccount = accountsResponse.find(acc => acc.platform === selectedPlatform);
      if (selectedAccount) {
        const analyticsData = await getYouTubeAnalytics(user.token, selectedAccount.accessToken, selectedAccount.refreshToken);
        setPlatformInsights(analyticsData);
      }
      setLastUpdated(new Date());
    } catch (error) {
      console.error('Error refreshing data:', error);
      setError('Failed to refresh data. Please try again.');
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    refreshData();
  }, [selectedPlatform]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountsResponse = await getConnectedAccounts(user.token);
        setConnectedAccounts(accountsResponse);
        
        const selectedAccount = accountsResponse.find(acc => acc.platform === selectedPlatform);
        if (selectedAccount) {
          let analyticsData;
          switch (selectedPlatform) {
            case 'youtube':
              const fetchYouTubeData = async () => {
                try {
                  const youtubeAccount = accountsResponse.find(acc => acc.platform === 'youtube');
                  if (youtubeAccount) {
                    const analyticsData = await getYouTubeAnalytics(user.token, youtubeAccount.accessToken, youtubeAccount.refreshToken);
                    setPlatformInsights(analyticsData);
                  }
                } catch (error) {
                  console.error('Error fetching YouTube data:', error);
                  // Handle error (e.g., show error message to user)
                }
              };
              await fetchYouTubeData();
              break;
            case 'instagram':
              analyticsData = await getInstagramInsights(selectedAccount.accessToken, user._id);
              break;
            case 'twitch':
            case 'tiktok':
              analyticsData = { message: `${selectedPlatform.charAt(0).toUpperCase() + selectedPlatform.slice(1)} data will be provided in the future.` };
              break;
            default:
              analyticsData = null;
          }
          if (selectedPlatform !== 'youtube') {
            setPlatformInsights(analyticsData);
          }
        } else {
          setPlatformInsights(null);
        }
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('An error occurred while fetching dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    if (user && user.token && selectedPlatform) {
      fetchData();
    } else {
      setLoading(false);
    }

  }, [user, refreshToken, selectedPlatform]);
  
  useEffect(() => {
    if (platformInsights && platformInsights.platform === 'youtube') {
      setYoutubeInsights(platformInsights);
    }
  }, [platformInsights]);

  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    setPlatformInsights(null); // Reset insights when changing platforms
  };

  const renderViewsOverTimeChart = () => {
    if (!youtubeInsights?.viewsOverTime) return <p>No data available for chart</p>;

    const data = {
      labels: youtubeInsights.viewsOverTime.map(row => row[0]),
      datasets: [{
        label: 'Views',
        data: youtubeInsights.viewsOverTime.map(row => parseInt(row[1])),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: '#e0e0e0'
          }
        },
        title: {
          display: true,
          text: 'Views Over Time',
          color: '#e0e0e0'
        },
        tooltip: {
          backgroundColor: '#333',
          titleColor: '#e0e0e0',
          bodyColor: '#e0e0e0'
        }
      },
      scales: {
        x: {
          ticks: { color: '#e0e0e0' },
          grid: { color: '#444' }
        },
        y: {
          ticks: { color: '#e0e0e0' },
          grid: { color: '#444' }
        }
      }
    };

    return <Line data={data} options={options} className={chartStyles.chart} />;
  };

  const renderOverviewStats = () => {
    if (!youtubeInsights?.overviewStats || youtubeInsights.overviewStats.length === 0) {
      return <p>No overview statistics available</p>;
    }
    
    const totals = youtubeInsights.overviewStats.reduce((acc, curr) => {
      curr.forEach((value, index) => {
        acc[index] = (acc[index] || 0) + parseFloat(value);
      });
      return acc;
    }, []);
  
    return (
      <div className={styles.overviewStats}>
        <h3>Overview Statistics (Last 28 Days)</h3>
        <p>Total Views: {totals[1] ? totals[1].toLocaleString() : 'No data'}</p>
        <p>Watch Time: {totals[2] ? `${Math.round(totals[2] / 60)} hours` : 'No data'}</p>
        <p>Subscribers Gained: {totals[3] ? totals[3].toLocaleString() : 'No data'}</p>
        <p>Estimated Revenue: ${totals[4] ? totals[4].toFixed(2) : 'No data'}</p>
        <p>Likes: {totals[5] ? totals[5].toLocaleString() : 'No data'}</p>
        <p>Comments: {totals[6] ? totals[6].toLocaleString() : 'No data'}</p>
        <p>Shares: {totals[7] ? totals[7].toLocaleString() : 'No data'}</p>
      </div>
    );
  };

  const renderRealTimeData = () => {
    if (!youtubeInsights?.realTimeData) return <p>No real-time data available</p>;
    return (
      <div className={styles.realTimeData}>
        <h3>Real-Time Data</h3>
        <p>Current Views: {youtubeInsights.realTimeData.viewCount || 'No data'}</p>
        <p>Current Subscribers: {youtubeInsights.realTimeData.subscriberCount || 'No data'}</p>
        <p>Current Videos: {youtubeInsights.realTimeData.videoCount || 'No data'}</p>
      </div>
    );
  };  

  const renderTopVideos = () => {
    if (!youtubeInsights?.topVideos || youtubeInsights.topVideos.length === 0) return <p>No top video data available</p>;
    return (
      <div className={styles.topVideos}>
        <h3>Top Performing Videos</h3>
        <div className={styles.videoGrid}>
          {youtubeInsights.topVideos.map((video, index) => (
            <div key={index} className={styles.videoCard}>
              <a href={`https://www.youtube.com/watch?v=${video[0]}`} target="_blank" rel="noopener noreferrer">
                <img src={`https://img.youtube.com/vi/${video[0]}/mqdefault.jpg`} alt={`Video thumbnail`} className={styles.videoThumbnail} />
              </a>
              <div className={styles.videoInfo}>
                <h4>{video[5] || 'Untitled'}</h4>
                <p>Views: {parseInt(video[1]).toLocaleString()}</p>
                <p>Watch Time: {Math.round(parseFloat(video[2]) / 60)} mins</p>
                <p>Avg View Duration: {Math.round(parseFloat(video[3]))} secs</p>
                <p>Avg View Percentage: {parseFloat(video[4]).toFixed(2)}%</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };  

  const renderDemographics = () => {
    if (!youtubeInsights?.demographics) return <p>No demographic data available</p>;
    
    return (
      <div className={styles.demographics}>
        <h3>Audience Demographics</h3>
        {Object.entries(youtubeInsights.demographics).map(([category, data]) => (
          <div key={category}>
            <h4>{category}</h4>
            <ul className={styles.dashboardList}>
              {data.map((item, index) => (
                <li key={index} className={styles.dashboardListItem}>
                  {item.join(': ')}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };
  
  return (
    <div className={styles.dashboard}>
      <h1 className={styles.dashboardTitle}>Influencer Dashboard</h1>
      <div className={styles.refreshSection}>
        <button onClick={refreshData} disabled={refreshing} className={styles.refreshButton}>
          {refreshing ? 'Refreshing...' : 'Refresh Data'}
        </button>
        {lastUpdated && (
          <p className={styles.lastUpdated}>Last updated: {lastUpdated.toLocaleString()}</p>
        )}
      </div>
      <div className={styles.platformSelector}>
        <button onClick={() => handlePlatformChange('youtube')}>YouTube</button>
        <button onClick={() => handlePlatformChange('instagram')}>Instagram</button>
        <button onClick={() => handlePlatformChange('twitch')}>Twitch</button>
        <button onClick={() => handlePlatformChange('tiktok')}>TikTok</button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : platformInsights ? (
        <div className={styles.platformInsights}>
          <h2 className={styles.sectionTitle}>{selectedPlatform.charAt(0).toUpperCase() + selectedPlatform.slice(1)} Analytics Overview</h2>
          {selectedPlatform === 'youtube' && (
            <>
              {renderOverviewStats()}
              {renderRealTimeData()}
              {renderTopVideos()}
              {renderDemographics()}
              <div className={styles.viewsOverTime}>
                <h3>Views Over Time</h3>
                {renderViewsOverTimeChart()}
              </div>
            </>
          )}
          {selectedPlatform === 'instagram' && (
            <p>{JSON.stringify(platformInsights)}</p>
          )}
          {(selectedPlatform === 'twitch' || selectedPlatform === 'tiktok') && (
            <p>{platformInsights.message}</p>
          )}
        </div>
      ) : (
        <p>No {selectedPlatform.charAt(0).toUpperCase() + selectedPlatform.slice(1)} account connected.</p>
      )}
    </div>
  );
};

export default InfluencerDashboard;
