const Impressum = () => {
      // JavaScript encoding for email and phone number
      const emailUser = 'dominik';
      const emailDomain = 'luenstaeden.de';
      const email = `${emailUser}@${emailDomain}`;
  
      const phoneNumber = '+49 170 4038139';
      const phoneHref = `tel:${phoneNumber.replace(/\s+/g, '')}`;

      return (
        <div>
          <h1>Impressum</h1>

          <p>Dominik Lünstäden<br />
          Lünstäden (Einzelunternehmer)<br />
          von-sauer-Straße 42<br />
          22761 Hamburg</p>

          <h2>Kontakt</h2>
          <p>Telefon: <a href={phoneHref}>{phoneNumber}</a><br />
          E-Mail: <a href={`mailto:${email}`}>kontakt@luenstaeden.com</a></p>

          <h2>Umsatzsteuer-ID</h2>
          <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
          DE361135965</p>

          <h2>EU-Streitschlichtung</h2>
          <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

          <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
      );
};

export default Impressum;
