import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import styles from '../styles/Auth.module.css';
import { login as apiLogin } from '../../utils/api';

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await apiLogin(credentials, {
        headers: {
          'X-XSRF-TOKEN': document.cookie.split('XSRF-TOKEN=')[1]
        }
      });
      login(response);
      navigate('/dashboard');
    } catch (err) {
      setError('Failed to log in');
    }
  };  

  return (
    <div className={styles.authContainer}>
      <h2 className={styles.authTitle}>Login</h2>
      {error && <p className={styles.errorText}>{error}</p>}
      <form onSubmit={handleSubmit} className={styles.authForm}>
        <div className={styles.formGroup}>
          <label htmlFor="username" className={styles.formLabel}>Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={credentials.username}
            onChange={handleChange}
            required
            className={styles.formInput}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password" className={styles.formLabel}>Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            required
            className={styles.formInput}
          />
        </div>
        <input type="hidden" name="_csrf" value={document.cookie.split('XSRF-TOKEN=')[1]} />
        <button type="submit" className={styles.authButton}>Login</button>
      </form>
    </div>
  );
};

export default Login;
